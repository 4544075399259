body {
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 24px;
}

h1 {
  font-weight: bolder;
  font-size: 60px;
  margin-bottom: 15px;
}

h3 {
  font-weight: bold;
}

.container {
  height: auto;
  margin-bottom: 50px;
}

.list-row {
  margin-top: 30px;
}

.add-edit, .list-items {
  border: 1px solid darkgray;
  border-radius: 10px;
  box-shadow: 2px 2px 5px gray;
  padding: 20px;
}

.add-edit {
  height: 480px;
}

.action-button {
  font-size: 20px;
  padding: 10px;
  margin-right: 15px;
}

.add-button {
  display: block;
  font-size: 20px;
  padding: 10px;
  margin-top: 15px;
}

label {
  display: block;
  margin-top: 10px;
}

input {
  width: 150px;
}

.edit-button {
  font-size: 20px;
  margin-top: 10px;
  display: block;
}

.error-message {
  color: red;
  font-size: 20px;
  font-weight: bold;
  margin-top: 22px;
}

.toggle {
  margin-top: 10px;
}

body.light-mode {
  background-color: #fff;
  color: #333;
  transition: background-color 0.3s ease;
}

body.dark-mode {
  background-color: #10171d;
  color: #dfdfdf;
}

.content {
  padding: 20px;
  margin: 0 auto;
  margin-top: 60px;
  max-width: 600px;
  font-size: 20px;
  line-height: 1.3em;

  code {
    background-color: #cbe9ff;
    border-radius: 2px;
    font-size: 1em;
    padding: 0 5px;
  }

  a {
    text-decoration: none;
    color: #006688;
  }

  button {
    color: #006688;
    background-color: transparent;
    border: none;
    font-size: 1em;
    padding: 0;
  }
}

body.dark-mode .content code {
  background-color: #1b2938;
}

body.dark-mode table {
  color: #dfdfdf;
}

body.dark-mode .content a {
  color: #ee09;
}

body.dark-mode .content button {
  color: #ee09;
}

.dark-mode-toggle {
  display: flex;
  margin: 0 auto;
  & > button {
    font-size: 1.2em;
    background: none;
    border: none;
    color: #ffe600;
    cursor: pointer;
    transition: color 0.3s ease;
    &:last-child {
      color: #666;
    }

    &:focus {
      outline: none;
    }
  }
}

.toggle-control {
  position: relative;
  padding: 0 4px;
  display: flex;
  align-items: center;
}

input[type='checkbox'].dmcheck {
  width: 40px;
  height: 10px;
  background: #555;
  position: relative;
  border-radius: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  vertical-align: 2px;
  outline: none;

  &:checked + label {
    left: 30px;
  }

  &:focus-visible {
    outline: solid 2px white;
  }

  & + label {
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    transition: all 0.3s ease;
    cursor: pointer;
    position: absolute;
    left: 2px;
    background: #fff;
    opacity: 0.9;
    background-color: #f6f6f6;
  }
}
